import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Divider from '@mui/material/Divider';

const FooterColumn = ({ title, text, image, subtext }) => {
    return (
        <Box 
            sx={{ 
                marginTop: '20px',
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center', 
                color: '#fff'
            }}
        >
            <Typography variant="body3" sx={{ fontWeight: '300', mb: 0.5 }}>
                {title}
            </Typography>
            <Divider sx={{ backgroundColor: 'white', height: 2, marginY: 2 }} />
            <Typography variant="body3" width="100%" sx={{ fontWeight: '300', color: '#fff', whiteSpace: 'pre-line' }}>
                {text}
            </Typography>
            <Box sx={{ fontSize: '2rem', marginTop: '10px' }}>
            {image}
            </Box>
            <Typography variant="body3" width="100%" sx={{ fontWeight: '300', color: '#fff' }}>
                {subtext}
            </Typography>
        </Box>
    );
};

export default FooterColumn;
