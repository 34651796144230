import React, { useState, useEffect } from "react";
import { 
  Box, 
  TextField, 
  Button, 
  Checkbox, 
  FormControlLabel, 
  Typography, 
  Alert, 
  CircularProgress, 
  InputAdornment 
} from "@mui/material";

import SectionTitle from './SectionTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, 
  faEnvelope, 
  faPhone, 
  faCalendarDays,
  faLocationPin,
  faLocationDot, 
  faPeopleGroup, 
  faSuitcaseRolling, 
  faCartFlatbedSuitcase 
} from '@fortawesome/free-solid-svg-icons';

const FormSection = () => {
  const variant="outlined";
  const url = "https://www.riverviewtravel.co.uk"; //http://localhost:8000

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    collectionAddress: "",
    destinationAddress: "",
    passengers: "",
    holdLuggage: "",
    carryOnLuggage: "",
    comments: "",
    agree: false,
    csrfToken: "",
    honeypot: ""
  });

  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state

  // Fetch CSRF token when the component loads
  useEffect(() => {
    fetch(url + "/csrf-token.php")
      .then((res) => res.json())
      .then((data) => {
        setFormData((prev) => ({ ...prev, csrfToken: data.csrfToken }));
        console.log(data.csrfToken);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]); // Clear previous errors
    setIsSubmitting(true); // Darken the background

    const response = await fetch(url + "/SendMailTest.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    });

    setIsSubmitting(false);

    if (response.ok) {
      alert("Your enquiry has been sent successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        date: "",
        collectionAddress: "",
        destinationAddress: "",
        passengers: "",
        holdLuggage: "",
        carryOnLuggage: "",
        comments: "",
        agree: false,
        csrfToken: formData.csrfToken, // Keep the CSRF token
        honeypot: "", // Reset honeypot field
      });
    } else {
      const errorData = await response.json();
      if (errorData.errors)
      {
        setErrors(errorData.errors);
      }
      else
      {
        alert("Failed to send your enquiry. Please try again later.");
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        maxWidth: 600,
        margin: "0 auto",
        padding: 2,
        borderRadius: 2,
      }}
    >
        <SectionTitle text="Get a Quote" />

      <Typography variant="body2" sx={{ fontWeight: '300', color: 'text.secondary' }}>
      Looking for a quote? Enter your details and we will get back to you.
      </Typography>
      {errors.length > 0 && (
        <Box sx={{ width: "100%" }}>
          {errors.map((error, index) => (
            <Alert key={index} severity="error" sx={{ marginBottom: 1 }}>
              {error}
            </Alert>
          ))}
        </Box>
      )}
      {/* Honeypot Field */}
      <TextField
        name="honeypot"
        value={formData.honeypot}
        onChange={handleChange}
        style={{ display: "none" }}
        tabIndex="-1"
        autoComplete="off"
      />
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        variant={variant}
        placeholder="Enter your name"
        fullWidth
        required
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faUser} style={{ fontSize: 14, color: '#000000' }} />
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Email Address"
        name="email"
        type="email"
        placeholder="Enter your email address"
        value={formData.email}
        onChange={handleChange}
        variant={variant}
        fullWidth
        required
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 14, color: '#000000' }} />
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Phone Number"
        name="phone"
        placeholder="Enter a contact telephone number"
        value={formData.phone}
        onChange={handleChange}
        variant={variant}
        fullWidth
        required
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faPhone} style={{ fontSize: 14, color: '#000000' }} />
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Date"
        name="date"
        type="date"
        value={formData.date}
        onChange={handleChange}
        variant={variant}
        fullWidth
        required
        slotProps={{
          inputLabel: {
            shrink: true
          },
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: 14, color: '#000000' }} />
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Collection Address"
        name="collectionAddress"
        placeholder="Tell us where you need collecting from"
        value={formData.collectionAddress}
        onChange={handleChange}
        variant={variant}
        fullWidth
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: 14, color: '#000000' }} />
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Destination Address"
        name="destinationAddress"
        placeholder="Tell us where you are going"
        value={formData.destinationAddress}
        onChange={handleChange}
        variant={variant}
        fullWidth
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faLocationPin} style={{ fontSize: 14, color: '#000000' }} />
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Passenger Number"
        name="passengers"
        type="number"
        placeholder="How many passengers?"
        value={formData.passengers}
        onChange={handleChange}
        variant={variant}
        fullWidth
        slotProps={{
          htmlInput: {
            min: 0
          },
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: 14, color: '#000000' }} />
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Number of Hold Luggage"
        name="holdLuggage"
        type="number"
        placeholder="How much luggage do you have?"
        value={formData.holdLuggage}
        onChange={handleChange}
        variant={variant}
        fullWidth
        slotProps={{
          htmlInput: {
            min: 0
          },
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faCartFlatbedSuitcase} style={{ fontSize: 14, color: '#000000' }} />              
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Number of Carry-On Luggage"
        name="carryOnLuggage"
        type="number"
        placeholder="Any hand-luggage?"
        value={formData.carryOnLuggage}
        onChange={handleChange}
        variant={variant}
        fullWidth
        slotProps={{
          htmlInput: {
            min: 0
          },
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSuitcaseRolling} style={{ fontSize: 14, color: '#000000' }} />
              </InputAdornment>
            ),
          }
        }}
      />
      <TextField
        label="Questions/Comments"
        name="comments"
        placeholder="Tell us anything else you think we should know"
        value={formData.comments}
        onChange={handleChange}
        variant={variant}
        fullWidth
        multiline
        rows={4}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="agree"
            checked={formData.agree}
            onChange={handleChange}
          />
        }
        label={
          <Typography variant="body2">
            I agree that Riverview Travel will use my data for contact purposes regarding this enquiry only. Please see <a href="https://www.riverviewtravel.co.uk/privacy-notice.html" target="_blank" rel="noopener noreferrer">Privacy Notice</a> for further details.
          </Typography>
        }
      />
      {isSubmitting ? (
        <CircularProgress />
      ) : (
        <Button
          type="submit"
          variant="contained"
          sx={{ backgroundColor: "#ffbf00", color: "#000000", fontWeight: '400', fontSize: '16px', width: "150px", height: "50px" }}
          disabled={!formData.agree}
        >
          Send
        </Button>
      )}
    </Box>
  );
};

export default FormSection;
