import React from 'react';
import { Box, Typography } from '@mui/material';

const ContactCard = ({ icon, text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start', // Aligns icon and text at the top
        width: '100%',
        minWidth: '100%',
        padding: '16px',
        borderRadius: '8px', // Optional rounded corners
      }}
    >
      {/* Icon Area */}
      <Box
        sx={{
          flex: '0 0 20%', // Fixed width of 20%
          display: 'flex',
          alignItems: 'center', // Center the icon vertically
          justifyContent: 'center', // Center the icon horizontally
        }}
      >
        {icon}
      </Box>

      {/* Text Area */}
      <Box
        sx={{
          flex: '1 1 80%', // Remaining 80% width
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ whiteSpace: 'pre-line', textAlign: 'left', color: '#fff', fontSize: '16px' }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactCard;
