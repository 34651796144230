import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';

import SectionTitle from './SectionTitle';

const CallNowSection = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fff',
                borderBottom: '1px solid #ddd',
            }}
        >
            <Box>
                {/* Phone Section */}
                <SectionTitle text="Call For A Free Quote" />

                <Box marginBottom='20px' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box width='200px' height='30px' backgroundColor='#ffbf00' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography variant="body1" sx={{ color: 'black', fontWeight: '400', fontSize: '16px' }}>
                            <Link href="tel:0203 601 5614" underline="none" variant="body1" color="inherit">0203 601 5614</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CallNowSection;