import React from 'react';
import { Box } from '@mui/material';
import InfoCard from './InfoCard'; // Assuming InfoCard is imported

const InfoCardContainer = ({ cards }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap', // Enables wrapping of cards
        justifyContent: 'center', // Centers cards horizontally
        gap: '16px', // Space between cards
        padding: '16px', // Optional padding
      }}
    >
      {cards.map((card, index) => (
        <Box
          key={index}
          sx={{
            flex: '1 1 calc(33.33% - 16px)', // Takes 1/3 of container width with space for gap
            minWidth: '250px', // Ensures a minimum width for smaller screens
            maxWidth: '400px', // Optional: limits the maximum width of a card
          }}
        >
          {card}
        </Box>
      ))}
    </Box>
  );
};

export default InfoCardContainer;
