import React from 'react';
import { Box, Typography } from '@mui/material';

const Hero = () => {
  return (
    <Box
      sx={{
        height: '400px',
        backgroundImage: 'url(banner.jpg)', // Replace with your image URL
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        textAlign: 'center'
      }}
    >
      <Typography variant="h3" sx={{ fontWeight: '300', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
        Executive Car Services Gravesend Sidcup Bexley
      </Typography>
    </Box>
  );
};

export default Hero;