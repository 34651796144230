import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import Link from '@mui/material/Link';

const TopBar = () => {
    return (
        <Box
            sx={{
                height: '40px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 16px',
                backgroundColor: '#fff',
                //borderBottom: '1px solid #ddd',
            }}
        >
            {/* Phone Section */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PhoneIcon sx={{ color: 'black', marginRight: '8px', fontSize: '14px' }} />
                <Link href="tel:0203 601 5614" underline="none" variant="body1" color="inherit">0203 601 5614</Link>
                <Typography variant="body1" marginLeft="10px" marginRight="10px" sx={{ color: 'black', fontWeight: 'thin' }}>
                - 
                </Typography>
                <Link href="tel:07485 076 447" underline="none" variant="body1" color="inherit">07485 076 447</Link>
            </Box>
            
            {/* Facebook Icon */}
            <IconButton edge="end" href="https://www.facebook.com/1riverviewtravel/" target="_blank" rel="noreferrer">
                <FacebookIcon sx={{ color: 'black' }} />
            </IconButton>
        </Box>
    );
};

export default TopBar;