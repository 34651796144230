import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';

const HeaderLogo = () => {
    return (
        <Box
            sx={{
                height: '90px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fff',
                borderBottom: '1px solid #ddd',
            }}
        >
            {/* Phone Section */}
            <img
                    alt="logo"
                    src={`./riverviewtravellogo.png`}
                />
        </Box>
    );
};

export default HeaderLogo;