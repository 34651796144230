import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';


const SectionTitle = ({text}) => {
    return (
        <Box backgroundColor='#ffffff' marginTop='20px' marginBottom='20px' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body3" sx={{ color: 'black', fontWeight: '300', fontSize: '32px' }}>
                {text}
            </Typography>
        </Box>
    );
};

export default SectionTitle;