import logo from './logo.svg';
import './App.css';

import Box from '@mui/material/Box';
import GDPRBanner from './components/GDPRBanner';
import TopBar from './components/TopBar';
import HeaderLogo from './components/HeaderLogo';
import Hero from './components/Hero';
import MakeABookingSection from './components/MakeABookingSection';
import BenefitsSection from './components/BenefitsSection';
import OurPromiseSection from './components/OurPromiseSection';
import CallNowSection from './components/CallNowSection';
import ServicesSection from './components/ServicesSection';
import FormSection from './components/FormSection';
import FooterSection from './components/FooterSection';
import BottomBar from './components/BottomBar';

function App() {
  return (
    <Box sx={{margin: '0px'}}>
      <GDPRBanner />
      <TopBar />
      <HeaderLogo />
      <Hero />
      <MakeABookingSection />
      <BenefitsSection />
      <OurPromiseSection />
      <CallNowSection />
      <ServicesSection />
      <FormSection />
      <FooterSection />
      <BottomBar />
    </Box>
  );
}

export default App;
