import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import {faEnvelope, faLocationDot, faPhone} from '@fortawesome/free-solid-svg-icons';
import FooterColumn from './FooterColumn';
import FooterContainer from './FooterContainer';
import ContactCard from './ContactCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FooterSection = () => {
    const contactItems = [
        <ContactCard 
            icon={<FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '24px', color: '#ffffff' }} />}
            text="Michael Spencer
(Trading as Riverview Travel)
461 Blackfen Road,
Sidcup, Kent, DA15 9NP"
        />,
        <ContactCard 
            icon={<FontAwesomeIcon icon={faPhone} style={{ fontSize: '24px', color: '#ffffff' }} />}
            text="0203 601 5614
07485 076 447"
        />,
        <ContactCard 
            icon={<FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '24px', color: '#ffffff' }} />}
            text="bookings@riverviewtravel.co.uk"
        />
    ];

    const columns = [
        <FooterColumn 
            title="ABOUT US" 
            text="Welcome to Riverview Travel, providing premium comfortable travel for business and pleasure. We provide professional and reliable airport transfers, cruise terminal transfers, London and Kent travel, London and Kent theatre transport, London and Kent hospital transport, Wedding cars, venue transport hire." 
            image={<img src='./publicCarriageOfficeFiltered.png' alt='Public Carriage' height='70px' />}
            subtext="Operator License Number 010038"
        />,
        <FooterColumn 
            title="OUR TOP REGIONS" 
            text="We cover many areas including but not limited to:
Bexley
Bexleyheath
Blackfen
Bromley
Chatham
Crayford
Dartford
Erith
Gillingham
Gravesend
Greenhithe
Longfield
Northfleet
Orpington
Sidcup
Strood
Swanley
Welling"
image={[]}
            subtext="Operator License Number 010038"
        />,
        <FooterColumn 
            title="CONTACT INFO" 
            text="" 
            image={contactItems}
        />
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 16px',
                backgroundColor: '#2f2f2f',
                paddingBottom: '40px'
            }}
        >
            <Box>
                <FooterContainer cards={columns} />
            </Box>
        </Box>
    );
};

export default FooterSection;