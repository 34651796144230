import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const BottomBar = () => {
    return (
        <Box
            sx={{
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 16px',
                backgroundColor: '#222222',
                borderBottom: '1px solid #ddd',
            }}
        >
            <Typography variant="body2" sx={{ color: 'white', fontWeight: 'thin' }}>
            Riverview Travel © 2024
            </Typography>
        </Box>
    );
};

export default BottomBar;