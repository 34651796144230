// src/components/GDPRBanner.js

import React, { useState, useEffect } from 'react';
import { Snackbar, Button, Typography, Box } from '@mui/material';

const GDPRBanner = () => {
  const [open, setOpen] = useState(false);

  // Check if the user has already accepted the GDPR terms
  useEffect(() => {
    const isAccepted = localStorage.getItem('gdprAccepted');
    if (!isAccepted) {
      setOpen(true);
    }
  }, []);

  // Handle user accepting the GDPR terms
  const handleAccept = () => {
    localStorage.setItem('gdprAccepted', 'true'); // Save to localStorage
    setOpen(false); // Close the banner
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={null} // Prevent auto-hide to allow the user to accept
      onClose={() => setOpen(false)}
      sx={{
        position: 'fixed', // Fix it at the bottom
        bottom: 0, // Align it at the bottom of the screen
        left: 0, // Ensure it spans full width
        right: 0, // Ensure it spans full width
        width: '100%', // Make sure the Snackbar takes full width
        zIndex: 9999, // Ensure the banner stays on top
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%', // Make sure the Box also takes full width
        }}
      >
        <Typography variant="body2">
          We use cookies to improve your experience. By continuing, you accept our use of cookies.
        </Typography>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleAccept}
        >
          Accept
        </Button>
      </Box>
    </Snackbar>
  );
};

export default GDPRBanner;
