import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';

import SectionTitle from './SectionTitle';

const OurPromiseSection = () => {
    const containerWidth = "70%";
    const textBottomMargin = "16px";

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 16px',
                backgroundColor: '#fff',
                borderBottom: '1px solid #ddd',
            }}
        >
            <Box>
                <SectionTitle text="Our Promise" />

                <Box 
                    sx={{ 
                        marginTop: '20px',
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        textAlign: 'center' 
                    }}
                >
                    <Typography variant="body2" width={containerWidth} marginBottom={textBottomMargin} sx={{ fontWeight: '300', color: 'text.secondary' }}>
                    We are so confident in our punctuality that if we are more than 5 minutes late collecting you on your outward journey to an airport you will travel for free.
                    </Typography>
                    <Typography variant="body2" width={containerWidth} marginBottom={textBottomMargin} sx={{ fontWeight: '300', color: 'text.secondary' }}>
                    We respond 24 hours a day 7 days a week.
                    </Typography>
                    <Typography variant="body2" width={containerWidth} marginBottom={textBottomMargin} sx={{ fontWeight: '300', color: 'text.secondary' }}>
                    You can book up to a year in advance.
                    </Typography>
                    <Typography variant="body2" width={containerWidth} marginBottom={textBottomMargin} sx={{ fontWeight: '300', color: 'text.secondary' }}>
                    We accept cash and all major credit and debit cards.
                    </Typography>
                    <Typography variant="body2" width={containerWidth} marginBottom={textBottomMargin} sx={{ fontWeight: '300', color: 'text.secondary' }}>
                    Email and text receipts can be provided on all card payments and on request for cash bookings.
                    </Typography>
                    <Typography variant="body2" width={containerWidth} marginBottom={textBottomMargin} sx={{ fontWeight: '300', color: 'text.secondary' }}>
                    We track flights so if they are early/late we will be there and we don't charge for any flight delays.
                    </Typography>
                    <Typography variant="body2" width={containerWidth} marginBottom={textBottomMargin} sx={{ fontWeight: '300', color: 'text.secondary' }}>
                    Infant seats supplied free of charge on request.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default OurPromiseSection;