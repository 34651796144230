import React from 'react';

const IconGallery = ({ icons }) => {
  return (
    <div style={styles.container}>
      {icons.map((icon, index) => (
        <div key={index} style={styles.iconWrapper}>
          {icon}
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap', // Enables wrapping to the next line
    justifyContent: 'center', // Centers icons horizontally
    gap: '16px', // Space between icons
    padding: '16px', // Optional padding for the container
    maxWidth: '100%', // Ensures the container respects its parent width
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px', // Set a uniform width for the icons
    height: '48px', // Set a uniform height for the icons
  },
};

export default IconGallery;
