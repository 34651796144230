import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const InfoCard = ({ icon, title, text }) => {
    return (
        <motion.div
      initial={{ opacity: 0, x: 100 }} // Start off-screen to the right
      whileInView={{ opacity: 1, x: 0 }} // Animate to center when in view
      viewport={{ once: true, amount: 0.3 }} // Trigger animation when 30% of the component is visible
      transition={{ duration: 0.6, ease: 'easeOut' }} // Customize duration and easing
    >
        <Box 
            sx={{ 
                marginTop: '20px',
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center' 
            }}
        >
            <Box sx={{ fontSize: '2rem' }}>{icon}</Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                {title}
            </Typography>
            <Typography variant="body2" width="200px" sx={{ fontWeight: '300', color: 'text.secondary' }}>
                {text}
            </Typography>
        </Box>
        </motion.div>
    );
};

export default InfoCard;
