import * as React from 'react';

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChampagneGlasses, faPlaneDeparture, faTaxi } from '@fortawesome/free-solid-svg-icons';
import InfoCardContainer from './InfoCardContainer';
import SectionTitle from './SectionTitle';
import InfoCard from './InfoCard';

const ServicesSection = () => {
    const iconSize = '36px';

    const cards = [
        <InfoCard 
            icon={<FontAwesomeIcon icon={faPlaneDeparture} style={{ fontSize: {iconSize}, color: '#000000' }} />} 
            title="Airport Transfers" 
            text="Luxurious, professional and reliable transfers to/from your chosen airport location. We cover Heathrow, Gatwick, Stansted, London City, Luton and Southend." 
        />,
        <InfoCard 
            icon={<FontAwesomeIcon icon={faTaxi} style={{ fontSize: {iconSize}, color: '#000000' }} />} 
            title="London Transport" 
            text="We cover all of London and the surrounding areas for your executive business or comfortable personal transport needs. We cover Bexley, Bexleyheath, Blackfen, Bromley, Chatham, Crayford, Dartford, Erith, Gillingham, Gravesend, Greenhithe, Longfield, Northfleet, Orpington, Sidcup, Strood, Swanley and Welling." 
        />,
        <InfoCard 
            icon={<FontAwesomeIcon icon={faChampagneGlasses} style={{ fontSize: {iconSize}, color: '#000000' }} />} 
            title="Special Events" 
            text="Reliable premium transport to and/or from the venue with single trip or day hire options. Whether it be for a wedding, a day at the races, or a trip to the theatre, we've got you covered." 
        />
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 16px',
                backgroundColor: '#fff',
                borderBottom: '1px solid #ddd',
                paddingBottom: '40px'
            }}
        >
            <Box>
                <SectionTitle text="Benefits" />
                <InfoCardContainer cards={cards} />
            </Box>
        </Box>
    );
};

export default ServicesSection;